/*
 * @Author: 宋绍华
 * @Date: 2022-04-21 10:45:39
 * @LastEditTime: 2022-10-22 11:57:48
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\store\state.ts
 */
import { Iterate } from "@/composables/useBoardBase";
import { ProjectItem } from "@/composables/useCommon";
import { getSession } from "@/utils";
import { systemBehaviorStore } from "@/utils/contantOptions";
export type USER = {
  department: string;
  department_code: string;
  duty_status: number;
  errcode: number;
  errmsg: string;
  gender: number;
  job_code: string;
  job_name: string;
  mobile: string;
  name: string;
  open_user_id: string;
  staff_no: string;
  telephone: string;
  userid: string;
  token: string;
  shr_staff_no?: string;
  update_passwd_time: string; //上次修改密码时间
  passwd_expire_time: string; //密码到期时间
  is_submit_demand: number; // 0 是不允许进入业务系统，is_submit_demand  = 1 时，进入提报需求列表
  source: number; // 来源 1.番茄配置部门员工 2.集团内非番茄配置部门 3.集团外部人员
} | null;

export type ENVLIST = {
  label: string;
  value: number;
} | null;

export type Permission = {
  config_operation: boolean;
  create_product: boolean;
  create_release_remind: boolean; // 是否有创建系统提醒权限
} | null;

export interface State {
  count: number;
  user: USER;
  // 员工列表
  employeeLists: Array<Record<string, any>>;
  // 环境变量列表
  envList: ENVLIST;
  // 访问配置模块权限
  permission: Permission;
  // employeeLists: Array<Record<string, any>>;
  // 产品列表
  // 缓存页面
  include: string[];
  workCalendarDay: string;
  productList: ProjectItem[]; // 全局項目list
  iterateList: Iterate[]; // 全局迭代list
  productId: number; // 全局当前项目id
  iterateId: number; // 全局当前迭代id
  productInfo: ProjectItem | null; // 全局项目列表
  currentIter: null | Iterate; // 全局当前迭代 currentIter 类型
  iterateContentVisible: boolean;
  addMemberVisible: boolean;
  hasUpdateNotices: boolean; // 是否有系统更新提醒
  systemUpdateType: number; // 最近 1， 更新历史2
  businessReceiveData: number; // 我的接收业务需求数量
  hasDecryptPremission: boolean;
  hasRouteError: boolean; // 路由是否发生错误
  isShowPwdExpireDialog: boolean; // 是否显示密码过期提醒
}

const state: State = {
  count: 0,
  user: getSession(systemBehaviorStore.systemUserinfo, true) as USER | null,
  employeeLists: [],
  envList: getSession("envList", true) as ENVLIST | null,
  permission: null,
  include: [],
  workCalendarDay: "",
  productInfo: null, // 全局项目列表
  currentIter: null, // 全局当前迭代
  productList: [], // 全局项目list
  iterateList: [], // 全局迭代list
  productId: 0, // 全局当前项目id
  iterateId: 0, // 全局当前迭代id
  iterateContentVisible: false,
  addMemberVisible: false,
  businessReceiveData: 0,
  hasDecryptPremission: false,
  hasRouteError: false,
  isShowPwdExpireDialog: false,
  hasUpdateNotices: false, // 是否有系统更新提醒
  systemUpdateType: 1
};
export default state;
