/*
 * @Author: 宋绍华
 * @Date: 2022-11-18 08:38:10
 * @LastEditTime: 2022-11-18 09:05:42
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\composables\useDecrypt.ts
 */
import { getConfigPermission, getDecryptList } from "@/api/request-modules/workbench";
import { awaitFunc } from "@/utils";
import { store } from "@/store";
import { MutationType } from "@/store/mutation-types";

export interface DecryptListReq {
  ciphertext?: string;
  operator?: string;
  // ip: string;
  begin_date?: string;
  end_date?: string;
  current_page: number;
  page_size: number;
}
export interface DecryptListRes {
  Id: number; //自增id，唯一
  Ciphertext: string; //密文
  AppId: number; //系统id
  Operator: string; //解密操作人
  OperationTime: string; //解密时间
  Ip: string; //解密ip
}
export interface DecryptListResponse {
  data: DecryptListRes[];
  total: number;
}
export default function useDecrypt() {
  //获取是否有配置中心对应key的权限
  const useGetKeyPermission = async (params: { key: string }): Promise<boolean> => {
    //  eslint-disable-next-line
    const [err, data]: [unknown, any] = await awaitFunc<{ key: string }, any>({
      asyncFunc: getConfigPermission,
      args: params
    });

    let hasPermission = false;

    if (data && data.permission) {
      hasPermission = true;
    }
    return hasPermission;
  };

  //获取解密记录的权限
  const getDecryptPremission = async () => {
    const hasPermission = await useGetKeyPermission({ key: "decryptStaffNo" });
    store.commit(MutationType.updateHasDecryptPremission, hasPermission);
    return hasPermission;
  };

  //获取解密记录列表
  const getDecryptListAction = async (params: DecryptListReq): Promise<DecryptListResponse> => {
    const [err, data]: [unknown, DecryptListResponse | null] = await awaitFunc<DecryptListReq, DecryptListResponse | null>({
      asyncFunc: getDecryptList,
      args: params
    });

    if (err || !data) {
      return { data: [], total: 0 };
    }
    return data;
  };

  return {
    useGetKeyPermission,
    getDecryptPremission,
    getDecryptListAction
  };
}
