/*
 * @Author: 宋绍华
 * @Date: 2022-10-17 17:32:54
 * @LastEditTime: 2022-10-28 18:48:10
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\api\request-modules\business.ts
 */
import axiosInstance from "../index";
import api from "../dict";
import { BusinessDemandHandleReq, DemandQuery, UpdateDemandStatusReq } from "@/composables/useBusiness";

/**
 *  @param id :目标系统
 *  @param name: 计划名称
 *  @param page_index: 分页
 *  @param page_size: 分页
 *  @param receive_status: 接收状态
 *  @param status 状态
 *  @description 获取提报需求列表
 * @returns {Promise<T>}
 */
export function getBusinessDemandList<T>(params: DemandQuery): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessDemandList, params);
}

/**
 *  @param id
 *  @description 获取详情
 * @returns {Promise<T>}
 */
export function getBusinessDemandDetails<T>(params: { id: number }): Promise<T> {
  return axiosInstance.get<T>(`${api.handleBusinessDemand}/${params.id}`);
}
/**
 *  @param id
 *  @description 删除提报需求
 * @returns {Promise<T>}
 */
export function deleteBusinessDemandDetailsApi<T>(params: { id: number }): Promise<T> {
  return axiosInstance.delete<T>(`${api.handleBusinessDemand}/${params.id}`);
}
/**
 *  @description 获取项目列表
 * @returns {Promise<T>}
 */
export function getBusinessProductList<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessProductList);
}

/**
 *
 *  @description 获取项目下的产品经理
 * @returns {Promise<T>}
 */
export function getBusinessProductStaffs<T>(params: { id: number }): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessProductStaff, params);
}

/**
 *  @description 获取一级业务部门列表
 * @returns {Promise<T>}
 */
export function getBusinessDepartmentList<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessDepartmentList);
}
/**
 *  @description 获取一级业务部门staff
 * @returns {Promise<T>}
 */
export function getBusinessDepartmentStaffList<T>(params: { organization_no: string }): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessDepartmentStaffList, params);
}

/**
 *  @description 创建新需求
 * @returns {Promise<T>}
 */
export function createBusinessDemand<T>(params: BusinessDemandHandleReq): Promise<T> {
  return axiosInstance.post<T>(api.handleBusinessDemand, params);
}
/**
 *  @description 编辑新需求
 * @returns {Promise<T>}
 */
export function editBusinessDemand<T>(params: BusinessDemandHandleReq): Promise<T> {
  return axiosInstance.put<T>(api.handleBusinessDemand, params);
}

/**
 *  @description 获取提报需求操作记录
 * @returns {Promise<T>}
 */
export function getBusinessDemandLog<T>(params: { id: number }): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessSubmitDemandLogs, params);
}

/**
 *  @param id 提报需求ID
 *  @param status 目标状态   3.同意  4.不同意
 *  @description 需求审批
 * @returns {Promise<T>}
 */
export function handleBusinessApproval<T>(params: { id: number; status: number; options?: string }): Promise<T> {
  return axiosInstance.post<T>(api.handleBusinessApproval, params);
}
/**
 *  @param id 提报需求ID
 *  @param receive_status 目标状态  2.已接收 3.已拒绝  4.已完成
 *  @description 接收处理
 * @returns {Promise<T>}
 */
export function handleBusinessReceive<T>(params: { id: number; receive_status: number; processing_time: number; options?: string }): Promise<T> {
  return axiosInstance.post<T>(api.handleBusinessReceive, params);
}

/**
 *  @description 我的待审批需求数
 * @returns {Promise<T>}
 */
export function getMyApprovalCountApi<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessMyApprovalCount);
}
/**
 *  @description 我的接收业务需求数量
 * @returns {Promise<T>}
 */
export function getReceiveCountApi<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessReceiveCount);
}

/**
 *  @param id 提报需求ID
 *  @description 通知审批人
 * @returns {Promise<T>}
 */
export function handleBusinessNoticeApi<T>(params: { id: number }): Promise<T> {
  return axiosInstance.post<T>(api.handleBusinessNotice, params);
}

/**
 *  @param id 提报需求ID
 *  @param manager_no 新产品经理编号
 *  @description 修改产品经理
 * @returns {Promise<T>}
 */
export function handleBusinessReceiveManagerApi<T>(params: { id: number; manager_no: string }): Promise<T> {
  return axiosInstance.put<T>(api.handleBusinessReceiveManager, params);
}
/**
 *  @description 系统项目经理列表
 * @returns {Promise<T>}
 */
export function getBusinessManagerListApi<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getBusinessManagerList);
}
/**
 *  @description 提报需求提报人下拉列表
 * @returns {Promise<T>}
 */
export function getSubmitDemandStaffApi<T>(params: { from: number }): Promise<T> {
  return axiosInstance.get<T>(api.getSubmitDemandStaff, params);
}

/**
 *  @param id 提报需求ID
 *  @param processing_state 处理状态  1.待开始 2.设计中 3.设计完成 4.已评审 5.开发中  6.已上线, 7 验收完成
 *  @description 需求提报处理状态变更
 * @returns {Promise<T>}
 */
export function handleBusinessReceiveStatusApi<T>(params: { id: number; processing_state: number }): Promise<T> {
  return axiosInstance.post<T>(api.handleBusinessReceiveStatus, params);
}

/**
 *  @description 待我处理提报数量
 * @returns {Promise<T>}
 */
export function getDemandPendingNumApi<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getDemandPendingNum);
}
/**
 *  @description 获取状态列表
 * @returns {Promise<T>}
 */
export function getDemandStatusListApi<T>(): Promise<T> {
  return axiosInstance.get<T>(api.getDemandStatusList);
}
/**
 * 需求批量导入
 * @param {String} product_id
 * @returns
 */
export function demandImportExcelApi<T>(file: HTMLFormElement): Promise<T> {
  return axiosInstance.post<T>(api.demandImport, file);
}

/**
 *  @description 提报需求导出
 * @returns {Promise<T>}
 */
export function demandExportApi<T>(params: DemandQuery): Promise<T> {
  return axiosInstance.get<T>(api.demandExport, params);
}
/**
 *  @description 取消需求关联关系
 * @returns {Promise<T>}
 */
export function cancelDemandAssociateApi<T>(params: { id: number; did: number }): Promise<T> {
  return axiosInstance.put<T>(api.cancelDemandAssociate, params);
}
/**
 *  @description 需求状态变更
 * @returns {Promise<T>}
 */
export function updateDemandStatusApi<T>(params: UpdateDemandStatusReq): Promise<T> {
  return axiosInstance.put<T>(api.updateDemandStatus, params);
}
/**
 *  @description 当前登录人参与项目的所有需求
 * @returns {Promise<T>}
 */
export function projectDemandTreeApi<T>(params: { tid: number }): Promise<T> {
  return axiosInstance.get<T>(api.projectDemandTree, params);
}

/**
 * 新增需求关联关系
 * @returns
 */
export function updateAssociateDemandApi<T>(params: { id: number; dids: number[] }): Promise<T> {
  return axiosInstance.post<T>(api.updateAssociateDemand, params);
}
