import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c70e3f42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header search-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table = _resolveComponent("el-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _directive_loadmore = _resolveDirective("loadmore")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["main", { 'fullscreen-ele-global': _ctx.isFullScreen }]),
      style: {"height":"auto"}
    }, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.tableData,
        stripe: _ctx.stripe,
        "row-key": _ctx.getRowKeys,
        style: {"width":"100%"},
        onSelectionChange: _ctx.handleSelectionChange,
        onRowClick: _ctx.handleRowClick,
        "highlight-current-row": _ctx.highlight,
        height: _ctx.height,
        "max-height": _ctx.maxHeight,
        "show-header": _ctx.showHeader,
        border: _ctx.border,
        "row-class-name": _ctx.tableRowClassName,
        onCellClick: _ctx.cellClick,
        onSortChange: _ctx.sortChange,
        "cell-style": _ctx.rowStyle,
        class: _normalizeClass(_ctx.className),
        "expand-row-keys": _ctx.expands,
        onExpandChange: _ctx.expandSelect,
        ref: "pageTable"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "main", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["data", "stripe", "row-key", "onSelectionChange", "onRowClick", "highlight-current-row", "height", "max-height", "show-header", "border", "row-class-name", "onCellClick", "onSortChange", "cell-style", "class", "expand-row-keys", "onExpandChange"])), [
        [_directive_loadmore, _ctx.loadMore]
      ]),
      (!_ctx.hiddenPagation)
        ? (_openBlock(), _createBlock(_component_pagination, {
            key: 0,
            total: _ctx.total,
            currentPage: _ctx.currentPage,
            layout: _ctx.layout,
            onHandlePagationChange: _ctx.handlePagationChange,
            bugType: _ctx.bugType,
            "page-sizes": _ctx.pageSizes,
            pageSize: _ctx.propsData.pageSize,
            pagerCount: 3
          }, {
            selectQuick: _withCtx(() => [
              _renderSlot(_ctx.$slots, "qucikSelect", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["total", "currentPage", "layout", "onHandlePagationChange", "bugType", "page-sizes", "pageSize"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}