
import { useStore } from "@/store";
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useMixin from "@/views/iteration/useMixin";
import { getSession, setSession } from "@/utils";
import useCommon from "@/composables/useCommon";
import { MutationType } from "@/store/mutation-types";
import { Iterate } from "@/composables/useBoardBase";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "navigation",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { handleInterface } = useCommon();
    const childRouter = ref<Array<Record<string, any>>>();
    const isShowIteration = ref(false);
    const isShowAddIteration = ref(false);
    const isJurisdiction = ref(false);
    const isHideTwoNav = ref(false);

    const disableIterationSelect = ref(false);
    const store = useStore();
    const include: string[] = store.state.include;
    const activiteIndex = ref(Number(getSession("currentActiviteRoute")) || 0);
    const { searchParams, handleConditionSearch, handleChangeDialogStatus, demandList, dialogRef } = useMixin(true);
    const receiveData = computed(() => store.state.businessReceiveData);
    // 是否展示iframe
    window.onload = () => {
      watch(
        () => route.path,
        () => {
          isShowAddIteration.value = route.path === "/project/iteration/homepage" ? true : false;
          isJurisdiction.value = false;
          childRouter.value = route.matched[2]?.children ? route.matched[2]?.children : [];
          childRouter.value = childRouter.value.filter((item) => item.meta?.hidden !== false);
          // 是否展示迭代筛选框
          isShowIteration.value = route.meta.isShowIterationSelect as boolean;
          // 是否展示二级菜单(主要在批量新增任务要隐藏二级菜单)
          isHideTwoNav.value = route.meta.isHideTwoNav as boolean;
          disableIterationSelect.value = route.meta.disabledIterationSelect as boolean;
          childRouter.value.forEach((itemRoute: Record<string, any>, index: number) => {
            if (route.path.includes(itemRoute.path)) {
              activiteIndex.value = index;
            }
          });
        },
        {
          immediate: true,
          deep: true
        }
      );
    };

    watch(
      () => route.path,
      () => {
        isShowIteration.value = route.meta.isShowIterationSelect as boolean;
        childRouter.value = route.matched[2]?.children ? route.matched[2]?.children : [];
        childRouter.value = childRouter.value.filter((item) => item.meta?.hidden !== false);
        isHideTwoNav.value = route.meta.isHideTwoNav as boolean;
        childRouter.value.forEach((itemRoute: Record<string, any>, index: number) => {
          if (route.path.includes(itemRoute.path)) {
            activiteIndex.value = index;
          }
        });
      },
      {
        immediate: true
      }
    );

    // 监听迭代id
    watch(
      () => searchParams.demand,
      (newVal) => {
        newVal && store.commit(MutationType.updateIterateId, newVal);
      },
      {
        immediate: true
      }
    );
    const handleActiviteRouter = (item: Record<string, any>, index: number) => {
      const { productId, iterateList, iterateId } = store.getters;
      const currentItem = iterateList.find((item: Iterate) => item.id === iterateId);
      if (item.name === "interface") {
        if (currentItem) {
          handleInterface(productId, currentItem.name);
        } else {
          ElMessage.warning("获取数据失败，请刷新当前页面");
        }
        return;
      }
      setSession("currentActiviteRoute", index.toString());
      activiteIndex.value = index;
      router.push({
        name: item.name,
        query: { ...router.currentRoute.value.query, iterateId: currentItem?.id }
      });
    };
    router.beforeResolve((to) => {
      const queryId = to.query.id;
      if (queryId) {
        searchParams.demand = parseInt(queryId as string);
        store.commit(MutationType.updateIterateId, queryId);
      }
    });
    return {
      receiveData,
      router,
      route,
      include,
      childRouter,
      handleActiviteRouter,
      activiteIndex,
      handleConditionSearch,
      demandList,
      searchParams,
      isShowIteration,
      handleChangeDialogStatus,
      dialogRef,
      disableIterationSelect,
      isJurisdiction,
      isShowAddIteration,
      isHideTwoNav
    };
  }
});
