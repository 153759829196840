import { getDepartmentList, getEmployeeList, getStaffList } from "@/api/request-modules/common";
import { getIterationDemand } from "@/api/request-modules/iteration";
import {
  AddNoticesNoticesApi,
  checkQrCodeApi,
  getMeterSphereProduct,
  getReleaseNoticesApi,
  getReleaseNoticesDetailsApi,
  getReleaseNoticesLatestApi,
  getReleaseNoticesListApi,
  passwordLoginApi,
  selectProductList,
  updateNoticesRecordApi,
  updatePasswordApi,
  messageLoginApi,
  createInviteLinkApi,
  getMsgApi,
  configEnterPriseWeChatApi
} from "@/api/request-modules/product";
import { store } from "@/store";
import { MutationType } from "@/store/mutation-types";
import { USER } from "@/store/state";
import { ResponseParams } from "@/types/response";
import { awaitFunc, clearSession, setSession } from "@/utils";

/*
 * @Author: 宋绍华
 * @Date: 2022-04-21 19:20:18
 * @LastEditTime: 2022-10-11 15:04:32
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\composables\useCommon.ts
 */
// 部门人员列表信息
export interface Department {
  department_code: string;
  name: string;
  p_department_code: string;
  son: Department[];
}

export interface ProjectItem {
  code: string;
  id: number;
  is_approval: number;
  name: string;
  state: number; // 1 未开始，2 进行中，3搁置 4 结项
  yapi_default_iteration_id: number;
  yapi_product_id: number;
}

// 项目成员下拉列表 接口响应类型
export interface StaffSelectResp {
  label: string; // 标签;
  options: {
    staff_no: string; // 成员编号;
    staff_name: string; // 成员名称;,
  }[];
}

// 需求下拉选择 接口响应类型
export interface DemandItemResp {
  id: number; // 需求ID;
  name: string; // 需求名称;
}

// 项目迭代下拉选择 接口响应类型
export interface InterateList {
  full_name: string;
  id: number;
  name: string;
  product_id: number;
  status: number;
  version: string;
  yapi_iteration_id: number;
}
// 上传附件类型
export interface FileUpload {
  create_by: string;
  create_time: string;
  creator: string;
  id: number;
  name: string;
  remark: string;
  title?: string;
  url?: string;
  size: number;
  type: number;
}
// 撇比排序
export interface OrderSort {
  column: Record<string, any>;
  order: string;
  prop: string;
}

export interface BasicsOption {
  value: string;
  label: string;
  children?: Record<string, any>[] | undefined;
}

export interface MetersphereId {
  meter_product_id: string;
}

export interface SystemReleaseNoticesResp {
  id: number; // id
  title: string; // 标题
  content: string; // 内容
}

export interface InviteLinkReq {
  type: number; // 类型 1.内部  2.外部
  product_id: number;
  link_validity?: number; // 链接有效期，单位：天
  mobiles?: string[]; // 外部人员手机号
  permission_expiration?: string; // 权限到期时间
}
export interface WechatConfigResp {
  corpid: string;
  agentid: string;
  timestamp: string;
  nonceStr: string;
  signature: string;
}

import { getSession } from "@/utils";
import { systemBehaviorStore } from "@/utils/contantOptions";
import { SourcePermiss } from "@/utils/enum";
import { ElMessage } from "element-plus";

export default function useCommon() {
  // 获取部门人员列表
  const useGetDepartmentList = async (): Promise<Department[] | null> => {
    const [err, data]: [unknown, Department[] | null] = await awaitFunc<any, Department[]>({
      asyncFunc: getDepartmentList
    });
    if (err || !data) return null;
    return data;
  };
  // 获取项目列表
  const useGetProjectList = async (params?: { staff_no: string }): Promise<ProjectItem[] | null> => {
    const [err, data]: [unknown, ProjectItem[] | null] = await awaitFunc<{ staff_no: string }, ProjectItem[]>({
      asyncFunc: selectProductList,
      args: params
    });
    if (err || !data) {
      return null;
    }
    return data;
  };

  // 设置项目缓存
  const setProjectCache = (list: ProjectItem[], iterId: number | null) => {
    const arr = list.map((item) => {
      return {
        iterationId: iterId,
        projectId: item.id,
        yapi_default_iteration_id: item.yapi_default_iteration_id
      };
    });

    setSession("cacheProject", JSON.stringify(arr));
  };

  // 根据部门id获取当前所有的员工
  const useGetEmployeeList = async (department_code: string): Promise<any | null> => {
    const [err, data]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<string, ResponseParams.ResponseDataSuccess>({
      asyncFunc: getEmployeeList,
      args: department_code
    });
    if (err || !data) return null;
    return data.data;
  };
  // 获取项目下的员工数据
  const useGetStaffList = async (params?: { product_id: number }): Promise<StaffSelectResp[] | null> => {
    const [err, data]: [unknown, StaffSelectResp[] | null] = await awaitFunc<{ product_id: number }, StaffSelectResp[]>({
      asyncFunc: getStaffList,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 获取项目下的需求
  const useGetDemandList = async (params?: { product_id: number }): Promise<DemandItemResp[] | null> => {
    const [err, data]: [unknown, DemandItemResp[] | null] = await awaitFunc<{ product_id: number }, DemandItemResp[]>({
      asyncFunc: getIterationDemand,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 获取metersphere项目接口
  const useGetMeterSphereProduct = async (product_id: number): Promise<MetersphereId | null> => {
    const [err, data]: [unknown, MetersphereId | null] = await awaitFunc<number, MetersphereId>({
      asyncFunc: getMeterSphereProduct,
      args: product_id
    });
    if (err || !data) return null;
    return data;
  };

  // 处理跳转到metersphere
  const handleInterface = async (productId: number, tag?: string) => {
    const data = await useGetMeterSphereProduct(productId);
    if (!data) return false;
    const user = getSession(systemBehaviorStore.systemUserinfo, true) as USER;
    if (!user) {
      ElMessage.error("staffno 不存在");
      return false;
    }
    if (user?.source === SourcePermiss.OUTSIDER) {
      ElMessage.warning("外部用户无法访问");
      return false;
    }
    const staffno = window.btoa(user.staff_no);
    const url = process.env.NODE_ENV === "development" ? "https://metersphere-test.rvet.cn" : "https://metersphere.petrvet.com";
    let link = `${url}/#/api/definition?productId=${data.meter_product_id}&staffno=${staffno}`;
    // 如果从迭代中过来的
    if (tag) {
      link += `&tag=${tag}`;
    }
    return window.open(link, "_blank");
  };

  // 获取系统更新信息
  const useGetSystemUpdateInfo = async (): Promise<boolean> => {
    const [err, data]: [unknown, { is_remind: boolean } | null] = await awaitFunc<void, { is_remind: boolean }>({
      asyncFunc: getReleaseNoticesApi,
      needCode: true
    });

    if (data && data.is_remind) {
      store.commit(MutationType.updateSystemNotices, data.is_remind);
      return data.is_remind;
    }
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return false;
  };

  // 获取版本提醒最近一条记录
  const useGetSystemFirstMsg = async () => {
    const [err, data]: [unknown, SystemReleaseNoticesResp | null] = await awaitFunc<void, SystemReleaseNoticesResp>({
      asyncFunc: getReleaseNoticesLatestApi
    });
    if (err || !data) return null;
    return data;
  };

  // 获取版本提醒历史列表
  const useGetSystemUpdateList = async () => {
    const [err, data]: [unknown, SystemReleaseNoticesResp[] | null] = await awaitFunc<void, SystemReleaseNoticesResp[]>({
      asyncFunc: getReleaseNoticesListApi
    });
    if (err || !data) return null;
    return data;
  };

  // 获取版本提醒详情
  const useGetSystemUpdateDetails = async (params: { release_remind_id: number }) => {
    const [err, data]: [unknown, SystemReleaseNoticesResp | null] = await awaitFunc<{ release_remind_id: number }, SystemReleaseNoticesResp>({
      asyncFunc: getReleaseNoticesDetailsApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 创建用户已读发布提醒记录
  const useUpdateSystemRecord = async (params: { id: number }) => {
    const [err]: [unknown, null] = await awaitFunc<{ id: number }, null>({
      asyncFunc: updateNoticesRecordApi,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 创建用户已读发布提醒记录
  const useAddSystemNotices = async (params: Omit<SystemReleaseNoticesResp, "id">) => {
    const [err]: [unknown, null] = await awaitFunc<Omit<SystemReleaseNoticesResp, "id">, null>({
      asyncFunc: AddNoticesNoticesApi,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 更新接口
  const useUpdatePassword = async (params: { passwd: string; confirm_passwd: string }) => {
    const [err]: [unknown, null] = await awaitFunc<{ passwd: string; confirm_passwd: string }, null>({
      asyncFunc: updatePasswordApi,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 扫码验证企微用户
  const useCheckQrCode = async (params: { code: string }) => {
    const [err]: [unknown, null] = await awaitFunc<{ code: string }, null>({
      asyncFunc: checkQrCodeApi,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 密码登录
  const usePasswordLogin = async (params: { mobile: string; passwd: string }) => {
    const [err, data]: [unknown, USER | null] = await awaitFunc<{ mobile: string; passwd: string }, USER>({
      asyncFunc: passwordLoginApi,
      args: params
    });

    if (err || !data) return null;
    return data;
  };

  // 短信登录
  const useMessageLogin = async (params: { mobile: string; code: string; is_invite?: string }) => {
    const [message, data]: [string | unknown, USER | null] = await awaitFunc<{ mobile: string; code: string; is_invite?: string }, USER>({
      asyncFunc: messageLoginApi,
      args: params,
      needMsg: true
    });
    if (!data) return null;

    return {
      data,
      message
    };
  };
  // 短信登录
  const useCreateInviteLink = async (params: InviteLinkReq) => {
    const [err, data]: [unknown, { link: string } | null] = await awaitFunc<InviteLinkReq, { link: string }>({
      asyncFunc: createInviteLinkApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 发送短信验证码
  const useGetMsgCommon = async (params: { mobile: string }) => {
    const [err]: [unknown, null] = await awaitFunc<{ mobile: string }, null>({
      asyncFunc: getMsgApi,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 企业微信配置接口
  const useConfigEnterPriseWeChat = async (params: { url: string }) => {
    const [err, data]: [unknown, WechatConfigResp | null] = await awaitFunc<{ url: string }, WechatConfigResp>({
      asyncFunc: configEnterPriseWeChatApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 登录成功后的业务回调
  const useLoginSuccess = (data: USER) => {
    store.commit("USER", data);
    store.dispatch("GETENV_LIST");
    setSession(systemBehaviorStore.systemUserinfo, JSON.stringify(data));
    const dirPath = getSession(systemBehaviorStore.systemRedirectUrl) as string;
    // 判断缓存中有jumpUrl， login、权限缓存就不用跳转了
    if (dirPath && typeof dirPath == "string" && !["/login", "/empty", "/noAccess"].includes(dirPath)) {
      const url = data!.is_submit_demand === 1 ? "/business" : dirPath.includes("/business") ? "/workbench/dashboard" : dirPath;
      location.href = url as string;
    } else {
      window.location.href = data!.is_submit_demand === 0 ? "/workbench/dashboard" : "/business";
    }

    clearSession(systemBehaviorStore.systemRedirectUrl);
    clearSession("jumpUrl");
  };

  return {
    useConfigEnterPriseWeChat,
    useGetMsgCommon,
    useCreateInviteLink,
    useMessageLogin,
    useLoginSuccess,
    usePasswordLogin,
    useCheckQrCode,
    useUpdatePassword,
    useAddSystemNotices,
    useUpdateSystemRecord,
    useGetSystemUpdateDetails,
    useGetSystemUpdateList,
    useGetSystemUpdateInfo,
    handleInterface,
    useGetMeterSphereProduct,
    useGetDemandList,
    useGetStaffList,
    useGetEmployeeList,
    setProjectCache,
    useGetDepartmentList,
    useGetProjectList,
    useGetSystemFirstMsg
  };
}
