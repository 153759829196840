import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd5b20f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"calc(100% - 10px)","border-radius":"6px","overflow-y":"hidden"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          mode: "out-in",
          appear: "",
          name: "zoom-fade"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, {
              max: 100,
              include: _ctx.include
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "content" }))
            ], 1032, ["include"]))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ]))
}