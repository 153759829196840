/*
 * @Author: 宋绍华
 * @Date: 2022-05-11 10:58:00
 * @LastEditTime: 2022-10-18 11:52:31
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\utils\directive.ts
 */
export default (app: any): void => {
  // 对element-plus button组件添加防抖
  app.directive("debounce", {
    beforeMount(el: any) {
      el.addEventListener("click", () => {
        el.classList.add("is-disabled");
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
          el.classList.remove("is-disabled");
        }, 1500); //我这里设置的是2000毫秒也就是2秒
      });
    }
  });
  // element-plus table组件加载更多
  app.directive("loadmore", {
    beforeMount(el: any, binding: any) {
      const selectWrap = el.querySelector(".el-table__body-wrapper");
      selectWrap.addEventListener("scroll", () => {
        if (selectWrap.scrollTop) {
          if (selectWrap.scrollHeight - selectWrap.scrollTop <= selectWrap.clientHeight + 1) {
            binding.value(1);
          }
        }
      });
    },
    updated(el: any, binding: any) {
      let selectWrap = el.querySelector(".el-table__body-wrapper");
      selectWrap.removeEventListener("scroll", binding.value());
      selectWrap = null;
    }
  });
  interface SpinningElement extends HTMLInputElement {
    inputEle?: HTMLInputElement;
    _blurHandler: (showing: Event) => void;
  }
  // input指令添加，去除首尾空格
  app.directive("trim", {
    beforeMount: (el: SpinningElement) => {
      let inputEle = HTMLInputElement as any;
      if (el.tagName !== "INPUT") {
        // input、textarea 框
        inputEle = el.querySelector("input") || el.querySelector("textarea");
      } else {
        inputEle = el;
      }
      const handler = function (event: any) {
        const newVal = event.target.value.trim();
        if (event.target.value != newVal) {
          event.target.value = newVal;
          const evt = document.createEvent("HTMLEvents");
          evt.initEvent("input", true, true);
          inputEle.dispatchEvent(evt);
        }
      };
      el.inputEle = inputEle;
      el._blurHandler = handler;
      inputEle.addEventListener("blur", handler);
    },
    unbind(el: any) {
      const { inputEle } = el;
      inputEle.removeEventListener("blur", el._blurHandler);
    }
  });

  app.directive("input-num", {
    beforeMount(el: any, binding: any, vnode: any) {
      el.addEventListener("change", (e: any) => {
        const { arg } = binding;
        const { value } = e.target;
        let val = 0; // 默认值为0
        const valIsNaN = isNaN(parseInt(value, 10)); // 如果是NaN, 设置为0
        /**
         * 1. arg 不存在，就按正常输入
         * 2. arg 存在，value 为 非数字，默认设置为0
         * 3. arg 存在，value 为数字，继续走下一步
         */
        if (!arg) {
          val = value;
        } else {
          if (valIsNaN) val = 0;
          else {
            // 正数
            if (arg === "plus") {
              val = Math.abs(parseInt(value, 10));
            } else if (arg === "int") {
              // 整数
              val = parseInt(value, 10);
            } else if (arg === "floatpos") {
              // 浮点正数,去精度
              val = Math.abs(parseFloat(parseFloat(value).toFixed(2)));
            } else {
              val = parseFloat(parseFloat(value).toFixed(2));
            }
          }
        }

        /**
         * 1. 如果vnode.data.model存在，类似于element ui 组件的 el-input，不能直接使用e.target.value 去赋值，要不然表面上是格式化
         * 了数字，但是实际提交的数字还是未格式化的，可以用vnode.data.model.callback(value)去赋值
         * 2. 如果自己写的input，可以直接使用e.target.value 去赋值
         */
        if (vnode?.data?.model) {
          vnode.data.model.callback(val);
        } else {
          e.target.value = val;
        }
      });
    },
    unbind(el: any) {
      el.removeEventListener("change", el._blurHandler);
    },
    unmounted(el: any) {
      el.removeEventListener("change", el._blurHandler);
    }
  });
};
