
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { Back } from "@element-plus/icons";

export default defineComponent({
  name: "backBtn",
  components: { Back },
  props: {
    name: {
      type: String
    }
  },
  setup(props) {
    const router = useRouter();

    const handleBack = () => {
      if (props?.name) {
        router.push({
          name: props.name
        });
      } else {
        router.go(-1);
      }
    };
    return () => {
      return (
        <el-button type="primary" onClick={() => handleBack()}>
          <el-icon>
            <back />
          </el-icon>
          返回
        </el-button>
      );
    };
  }
});
