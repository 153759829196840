/*
 * @Author: 宋绍华
 * @Date: 2022-04-21 10:45:39
 * @LastEditTime: 2022-10-22 11:14:24
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\store\mutation-types.ts
 */
export enum MutationType {
  increment = "INCREMENT",
  iterateContentVisible = "ITERATECONTENT",
  addMemberVisible = "ADDMEMBERVISIBLE",
  user = "USER",
  employeeLists = "EMPLOYEE_LIST",
  envList = "ENVLIST",
  permission = "PERMISSION",
  include = "INCLUDE",
  updateCalendarDay = "UPDATECALENDARDAY",
  updateProductInfo = "UPDATEPRODUCTINFO",
  updateCurrentIter = "UPDATECURRENTITER",
  updateProductList = "UPDATEPRODUCTLIST",
  updateProductId = "UPDATEPRODUCTID", // 项目Id
  updateIterateId = "UPDATEITERATEID", // 迭代Id
  updateIterateList = "UPDATEITERATELIST", // 迭代LIST
  updateBusinessReceiveData = "UPDATEBUSINESSRECEIVEDATA", // 我的接收业务需求数量
  updateHasDecryptPremission = "UPDATEHASDECRYPTPREMISSION",
  updateSystemNotices = "UPDATESYSTEMNOTICES", // 是否有系统更新提醒
  updateSystemNoticesType = "UPDATESYSTEMNOTICESTYPE", // 是否有系统type 1, 2
  hasRouteError = "HASROUTEERROR", // 是否路由发成错误
  updatePwdExpireDialog = "UPDATEPWDEXPIREDIALOG" // 是否显示密码过期提醒
}
