/*
 * @Author: 宋绍华
 * @Date: 2022-10-17 17:26:59
 * @LastEditTime: 2022-10-28 18:49:05
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\composables\useBusiness.ts
 */
import {
  cancelDemandAssociateApi,
  createBusinessDemand,
  deleteBusinessDemandDetailsApi,
  demandImportExcelApi,
  editBusinessDemand,
  getBusinessDemandDetails,
  getBusinessDemandList,
  getBusinessDemandLog,
  getBusinessDepartmentList,
  getBusinessDepartmentStaffList,
  getBusinessManagerListApi,
  getBusinessProductList,
  getBusinessProductStaffs,
  getDemandPendingNumApi,
  getDemandStatusListApi,
  getMyApprovalCountApi,
  getSubmitDemandStaffApi,
  handleBusinessApproval,
  handleBusinessNoticeApi,
  handleBusinessReceive,
  handleBusinessReceiveManagerApi,
  handleBusinessReceiveStatusApi,
  projectDemandTreeApi,
  updateAssociateDemandApi,
  updateDemandStatusApi
} from "@/api/request-modules/business";
import { store } from "@/store";
import { MutationType } from "@/store/mutation-types";
import { ResponseParams } from "@/types/response";
import { awaitFunc } from "@/utils";
import { reactive } from "vue";
import { MultipleStaffItem } from "./useBoardBase";
import { TableListBaseReq } from "./useProfileOverview";

export interface DemandQuery extends TableListBaseReq {
  quick_filter?: number; // 快捷筛选 1.待我处理 2.我处理的 3.我创建的
  priority?: number; // 需求急迫性 1.非常紧急 2.紧急 3.一般
  name?: string;
  status?: number; // 数组可传多个参数， 状态 1.草稿 2.待审核 3.待处理 4.待开始 5.设计中 6.设计完成 7.已评审 8.开发中 9.已上线（待验收）10.待评价（已验收）11.已结束 12.已驳回 13.已作废
  create_by?: string; // 提报人编码
  approver_no?: string; //  审批人编号
  manager_no?: string; // 产品经理编号
  tid?: number; // 数组可传多个参数，目标系统ID
  organization_no?: number; // 数组可传多个参数，提交人业务部门编号
  submit_time?: string[]; // 第一个参数为开始，第二个参数为结束； 提交时间
  expected_release_time?: string[]; // 第一个参数为开始，第二个参数为结束； 期望上线时间
  estimated_release_time?: string[]; // ，第一个参数为开始，第二个参数为结束； 计划上线时间
  online_time?: string[]; // 第一个参数为开始，第二个参数为结束； 实际上线时间
}

export interface DemandRespItem {
  create_time: string; // 提报时间
  t_name: string; // 目标系统名称
  name: string; // 需求名称
  tid: number; // 目标系统ID
  id: number; //
  primary_department: string; // 业务部门
  status: number; // 状态 1.草稿 2.待审核 3.待处理 4.待开始 5.设计中 6.设计完成 7.已评审 8.开发中 9.已上线（待验收）10.待评价（已验收）11.已结束 12.已驳回 13.已作废
  handler: string; // 待处理人
  handler_no: string; // 待处理人编号
  create_by: string; // 提报人编号
  creator: string; // 提报人姓名
  estimated_release_time: string; // 计划上线时间
}

export interface BusinessDemandResp {
  count: number;
  list: DemandRespItem[];
}

export interface BusinessDemandDetailRespItem {
  id: number; //  提报需求ID
  name: string; //  需求名称
  t_name: string; // 目标系统名称
  manager_name: string; // 产品经理名称
  desc: string; //  需求描述
  value_desc: string; // 需求价值
  demand_type_name: string; // 需求类型名称
  tid: number; // 目标系统id
  manager_no: string; //  产品经理编号
  type: number; //  需求类型
  priority: number; //  优先级
  processing_state: number;
  priority_name: string; //  优先级名称
  release_time: string; //  上线时间
  related_products: string; // 相关项目ids
  related_products_name: string; // 相关系统名称 相关产品，目标产品id多个逗号隔开
  related_departments: string; // 相关业务部门 相关部门，部门编号，多个逗号隔开
  related_departments_name: string; // 相关业务部门名称
  approver_no: string; // 审批人编号
  approver_name: string; // 审批人姓名
  create_by: string; // 提交人编号
  creator: string; // 提交人姓名
  submit_time: string; // 提交时间
  approval_time: string; // 审批时间
  handlilng: number; // 待处理时长
  status?: number; // 状态 1.草稿 2.提交
  receive_status: number; // 接收状态  1.待处理 2.已接受 3.已拒绝  4.已完成
  attachment?: Attachment[]; // 附件
  expected_release_time: string;
  estimated_release_time: string;
  urgency_cause: string;
  system_module: string;
  service_issue: string;
  related_demand: BusinessRelatedDemandResp[];
  handler_no: string;
}

export interface BusinessRelatedDemandResp {
  create_time: string;
  id: number;
  name: string;
  staff_name: string;
  staff_no: string;
  status: number;
}

// 项目
export interface businessProduct {
  id: number; // 目标系统id
  name: string; // 目标系统名称
  product_id: number; // 番茄项目id
  disabled?: boolean;
}

// 项目下的产品经理
export interface BusinessProductStaff {
  staff_no: string; // 用户编号
  staff_name: string; // 用户名称
  tid?: number;
  job_path: string;
}

export interface BusinessDepartmentItem {
  name: string; // 部门名称
  organization_no: string; // 组织编号
}
export interface BusinessDepartmentStaff {
  staff_no: string; // 员工编号
  staff_name: string; // 员工姓名
  job_path: string; // 职位路径
  job_name: string; // 职位名称
}

// 新建、编辑需求
export interface BusinessDemandHandleReq {
  id?: number;
  name: string; //  需求名称
  manager_name: string; // 产品经理名称
  desc: string; //  需求描述
  value_desc: string; // 需求价值
  tid: number; // 目标系统id
  manager_no: string; //  产品经理编号
  priority: number; //  优先级
  related_products: string; // 相关项目ids
  related_departments: string; // 相关业务部门 相关部门，部门编号，多个逗号隔开
  approver_no: string; // 审批人编号
  approver_name: string; // 审批人姓名
  status: number; // 状态 1.草稿 2.提交
  service_issue: string; // 业务问题
  system_module: string; // 系统模块
  urgency_cause: string; // 紧急原因
  expected_release_time: string; // 期望上线时间
  attachment?: Attachment[];
  estimated_release_time?: string; // 预计上线时间
  field?: string; // 更新字段名称
}

// 编辑需求
export type BusinessDemandEditReq = Partial<BusinessDemandHandleReq> & { id: number };

export interface BusinessSubmitDemandLog {
  id: number; // 记录ID
  create_time: string; // 创建时间
  staff_no: string; // 用户编号
  staff_name: string; // 用户名称
  content: string; // 内容
}

export interface BusinessReceiveData {
  num: number;
  is_show: number; // 是否显示业务需求栏目 1.是 0.否
}

export interface BusinessRt {
  products: businessProduct[];
  departments: BusinessDepartmentItem[];
  departmentStaffs: BusinessDepartmentStaff[];
  myApprovalCount: number; // 我的待审批需求数
  businessReceiveData: number; // 我的接收业务需求数量
}

export interface Attachment {
  id?: number; // 附件ID;
  name?: string; // 附件名称;
  size?: number; // 附件大小;
  url?: string; // 附件地址;
}

// 需求状态变更
export interface UpdateDemandStatusReq {
  id: number; // 提报需求ID
  status: number; // 状态
  option?: string; // 处理意见
  estimated_release_time?: string; // 预计上线时间
  quality?: number; // 产品质量
  pm?: number; // 项目管理
  satisfaction?: number; // 满意度
}

export default function useBusiness() {
  const businessRt = reactive<BusinessRt>({
    products: [],
    departments: [],
    departmentStaffs: [],
    myApprovalCount: 0,
    businessReceiveData: 0
  });

  // 创建新的需求
  const useCreateBusinessDemand = async (params: BusinessDemandHandleReq): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<BusinessDemandHandleReq, ResponseParams.ResponseDataSuccess>({
      asyncFunc: createBusinessDemand,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 编辑需求
  const useEditBusinessDemand = async (params: BusinessDemandEditReq): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<BusinessDemandEditReq, ResponseParams.ResponseDataSuccess>({
      asyncFunc: editBusinessDemand,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 编辑需求
  const useHandleApproval = async (params: { id: number; status: number; options?: string }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<
      { id: number; status: number; options?: string },
      ResponseParams.ResponseDataSuccess
    >({
      asyncFunc: handleBusinessApproval,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 接收处理
  const useHandleReceive = async (params: { id: number; receive_status: number; processing_time: number; options?: string }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<
      { id: number; receive_status: number; processing_time: number; options?: string },
      ResponseParams.ResponseDataSuccess
    >({
      asyncFunc: handleBusinessReceive,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 通知审批人
  const useHandleNotice = async (params: { id: number }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<{ id: number }, ResponseParams.ResponseDataSuccess>({
      asyncFunc: handleBusinessNoticeApi,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 通知审批人
  const useModiyManager = async (params: { id: number; manager_no: string }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<{ id: number; manager_no: string }, ResponseParams.ResponseDataSuccess>(
      {
        asyncFunc: handleBusinessReceiveManagerApi,
        args: params,
        needCode: true
      }
    );
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 获取需求审批单列表
  const useGetBusinessDemandList = async (params?: DemandQuery): Promise<BusinessDemandResp | null> => {
    const [err, data]: [unknown, BusinessDemandResp | null] = await awaitFunc<DemandQuery, BusinessDemandResp>({
      asyncFunc: getBusinessDemandList,
      args: params,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取我的待审批需求数
  const useGetMyApprovalCount = async (): Promise<number | null> => {
    const [err, data]: [unknown, { num: number } | null] = await awaitFunc<null, { num: number }>({
      asyncFunc: getMyApprovalCountApi,
      needLoading: false
    });
    if (err || !data) return null;
    return data.num;
  };

  // 获取目标系统列表
  const useGetBusinessProductList = async (): Promise<businessProduct[] | null> => {
    const [err, data]: [unknown, businessProduct[] | null] = await awaitFunc<null, businessProduct[]>({
      asyncFunc: getBusinessProductList,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取详情
  const useGetBusinessDemandDetails = async (params: { id: number }): Promise<BusinessDemandDetailRespItem | null> => {
    const [err, data]: [unknown, BusinessDemandDetailRespItem | null] = await awaitFunc<{ id: number }, BusinessDemandDetailRespItem>({
      asyncFunc: getBusinessDemandDetails,
      args: params,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取一级业务部门列表
  const useGetBusinessDepartmentList = async (): Promise<BusinessDepartmentItem[] | null> => {
    const [err, data]: [unknown, BusinessDepartmentItem[] | null] = await awaitFunc<null, BusinessDepartmentItem[]>({
      asyncFunc: getBusinessDepartmentList,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };
  // 获取一级业务部门列表
  const useGetBusinessDepartmentStaff = async (): Promise<BusinessDepartmentStaff[] | null> => {
    const [err, data]: [unknown, BusinessDepartmentStaff[] | null] = await awaitFunc<null, BusinessDepartmentStaff[]>({
      asyncFunc: getBusinessDepartmentStaffList,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取需求审批单列表
  const useGetBusinessProductStaffList = async (params: { id: number }): Promise<BusinessProductStaff[] | null> => {
    const [err, data]: [unknown, BusinessProductStaff[] | null] = await awaitFunc<{ id: number }, BusinessProductStaff[]>({
      asyncFunc: getBusinessProductStaffs,
      args: params,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取报需求操作记录
  const useGetBusinessSubmitDemandLogs = async (params: { id: number }): Promise<BusinessSubmitDemandLog[] | null> => {
    const [err, data]: [unknown, BusinessSubmitDemandLog[] | null] = await awaitFunc<{ id: number }, BusinessSubmitDemandLog[]>({
      asyncFunc: getBusinessDemandLog,
      args: params,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取系统项目经理列表
  const useGetBusinessManagerList = async (): Promise<BusinessProductStaff[] | null> => {
    const [err, data]: [unknown, BusinessProductStaff[] | null] = await awaitFunc<null, BusinessProductStaff[]>({
      asyncFunc: getBusinessManagerListApi,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };

  // 获取系统项目经理列表
  const useGetSubmitDemandStaff = async (params: { from: number }): Promise<MultipleStaffItem[] | null> => {
    const [err, data]: [unknown, MultipleStaffItem[] | null] = await awaitFunc<{ from: number }, MultipleStaffItem[]>({
      asyncFunc: getSubmitDemandStaffApi,
      needLoading: false,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 获取状态列表
  const useGetDemandStatusList = async (): Promise<{ id: number; name: string }[] | null> => {
    const [err, data]: [unknown, { id: number; name: string }[] | null] = await awaitFunc<null, { id: number; name: string }[]>({
      asyncFunc: getDemandStatusListApi,
      needLoading: false
    });
    if (err || !data) return null;
    return data;
  };
  // 获取系统项目经理列表
  const useGetDemandPendingNum = async (): Promise<number> => {
    const [err, data]: [unknown, { num: number } | null] = await awaitFunc<{ from: number }, { num: number }>({
      asyncFunc: getDemandPendingNumApi,
      needLoading: false
    });
    if (err || !data) return 0;

    return data.num;
  };

  // 需求提报处理状态变更
  const useHandleBusinessReceiveStatus = async (params: { id: number; processing_state: number }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<
      { id: number; processing_state: number },
      ResponseParams.ResponseDataSuccess
    >({
      asyncFunc: handleBusinessReceiveStatusApi,
      needLoading: true,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 取消需求关联关系
  const useCancelDemandAssociate = async (params: { id: number; did: number }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<{ id: number; did: number }, ResponseParams.ResponseDataSuccess>({
      asyncFunc: cancelDemandAssociateApi,
      needLoading: true,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };
  // 需求状态变更
  const useUpdateDemandStatus = async (params: UpdateDemandStatusReq): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<UpdateDemandStatusReq, ResponseParams.ResponseDataSuccess>({
      asyncFunc: updateDemandStatusApi,
      needLoading: true,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 提报需求导入
  const useDemandImportExcel = async (file: HTMLFormElement): Promise<{ success: boolean; url: string } | null> => {
    const [err, data]: [unknown, { success: boolean; url: string } | null] = await awaitFunc<HTMLFormElement, { success: boolean; url: string }>({
      asyncFunc: demandImportExcelApi,
      args: file
    });
    if (err || !data) return null;
    return data;
  };
  // 当前登录人参与项目的所有需求
  const useProjectDemandTree = async (params: { tid: number }): Promise<any | null> => {
    const [err, data]: [unknown, { success: boolean; url: string } | null] = await awaitFunc<{ tid: number }, any>({
      asyncFunc: projectDemandTreeApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 新增需求关联关系
  const useUpdateAssociateDemand = async (params: { id: number; dids: number[] }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<{ id: number; dids: number[] }, ResponseParams.ResponseDataSuccess>({
      asyncFunc: updateAssociateDemandApi,
      needLoading: true,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 删除提报需求
  const useDeleteBusinessDemandDetails = async (params: { id: number }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<{ id: number }, ResponseParams.ResponseDataSuccess>({
      asyncFunc: deleteBusinessDemandDetailsApi,
      needLoading: true,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 获取项目列表
  const useGetProducts = async () => {
    const data = await useGetBusinessProductList();
    if (data) {
      businessRt.products = data;
    }
  };

  // 获取项目列表
  const getDepartments = async () => {
    const data = await useGetBusinessDepartmentList();
    if (data) {
      businessRt.departments = data;
    }
  };

  // 获取项目列表
  const getDepartmentStaffs = async () => {
    const data = await useGetBusinessDepartmentStaff();
    if (data) {
      businessRt.departmentStaffs = data;
    }
  };
  // 获取项目列表
  const getMyApprovalCount = async () => {
    const data = await useGetMyApprovalCount();
    if (data) {
      businessRt.myApprovalCount = data;
    }
  };
  // 获取项目列表
  const getReceiveCount = async () => {
    const data = await useGetDemandPendingNum();

    if (data) {
      businessRt.businessReceiveData = data;
      store.commit(MutationType.updateBusinessReceiveData, data);
    }
  };

  return {
    useDeleteBusinessDemandDetails,
    useUpdateAssociateDemand,
    useProjectDemandTree,
    useUpdateDemandStatus,
    useCancelDemandAssociate,
    useDemandImportExcel,
    useGetDemandStatusList,
    useHandleBusinessReceiveStatus,
    useGetBusinessManagerList,
    useModiyManager,
    useHandleReceive,
    useHandleApproval,
    useGetBusinessSubmitDemandLogs,
    useEditBusinessDemand,
    useCreateBusinessDemand,
    getDepartmentStaffs,
    getDepartments,
    useGetBusinessDemandList,
    useGetBusinessDemandDetails,
    useGetBusinessProductList,
    useGetProducts,
    useGetBusinessProductStaffList,
    getMyApprovalCount,
    getReceiveCount,
    useGetSubmitDemandStaff,
    useHandleNotice,
    businessRt
  };
}
