
import { toRefs, ref, defineComponent, computed } from "vue";
export default defineComponent({
  name: "Tab",
  props: {
    tabsValue: {
      type: String,
      default: () => "1"
    },
    editableTabs: {
      type: Array,
      default: () => [
        {
          name: "全部",
          id: "1"
        }
      ]
    }
  },
  setup(props, { emit }) {
    const currentTabVal = computed(() => props.tabsValue);
    const searchVal = ref(window.location.href.includes("guifan") ? "规范" : "");
    const handleTab = (params: Record<string, any>) => {
      const obj: Record<string, any> = { name: params.props.label, id: params.props.name };
      emit("handleTab", obj);
    };

    const handleConditionSearch = () => {
      emit("handleUpdateSearchVal", searchVal.value);
    };

    const handleSearch = (val: string) => {
      if (!val) emit("handleUpdateSearchVal", "");
    };

    return {
      ...toRefs(props),
      handleTab,
      handleConditionSearch,
      handleSearch,
      currentTabVal,
      searchVal
    };
  }
});
