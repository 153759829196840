import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "el-calendar" }
const _hoisted_2 = {
  key: 0,
  class: "el-calendar__body"
}
const _hoisted_3 = {
  key: 1,
  class: "el-calendar__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_table = _resolveComponent("date-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.validatedRange.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_date_table, {
            todoDay: _ctx.todoDay,
            date: _ctx.date,
            "selected-day": _ctx.realSelectedDay,
            onPick: _ctx.pickDay
          }, _createSlots({ _: 2 }, [
            (_ctx.$slots.dateCell)
              ? {
                  name: "dateCell",
                  fn: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, "dateCell", _normalizeProps(_guardReactiveProps(data)))
                  ])
                }
              : undefined
          ]), 1032, ["todoDay", "date", "selected-day", "onPick"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validatedRange, (range_, index) => {
            return (_openBlock(), _createBlock(_component_date_table, {
              key: index,
              date: range_[0],
              "selected-day": _ctx.realSelectedDay,
              range: range_,
              "hide-header": index !== 0,
              onPick: _ctx.pickDay
            }, _createSlots({ _: 2 }, [
              (_ctx.$slots.dateCell)
                ? {
                    name: "dateCell",
                    fn: _withCtx((data) => [
                      _renderSlot(_ctx.$slots, "dateCell", _normalizeProps(_guardReactiveProps(data)))
                    ])
                  }
                : undefined
            ]), 1032, ["date", "selected-day", "range", "hide-header", "onPick"]))
          }), 128))
        ]))
  ]))
}