
import { defineComponent } from "vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "Transition",
  setup() {
    const store = useStore();
    const include: string[] = store.state.include;
    return {
      include
    };
  }
});
