import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61bdb7e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "el-calendar-day" }
const _hoisted_4 = { class: "today" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass({
      'el-calendar-table': true,
      'is-range': _ctx.isInRange
    }),
    cellspacing: "0",
    cellpadding: "0"
  }, [
    (!_ctx.hideHeader)
      ? (_openBlock(), _createElementBlock("thead", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekDays, (day) => {
            return (_openBlock(), _createElementBlock("th", { key: day }, _toDisplayString(day), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: index,
          class: _normalizeClass({
          'el-calendar-table__row': true,
          'el-calendar-table__row--hide-border': index === 0 && _ctx.hideHeader
        })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (cell, key) => {
            return (_openBlock(), _createElementBlock("td", {
              key: key,
              class: _normalizeClass(_ctx.getCellClass(cell)),
              onClick: ($event: any) => (_ctx.pickDay(cell))
            }, [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "dateCell", {
                  data: _ctx.getSlotData(cell)
                }, () => [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.isToday(cell.text, cell)), 1)
                ], true)
              ])
            ], 10, _hoisted_2))
          }), 128))
        ], 2))
      }), 128))
    ])
  ], 2))
}