/*
 * @Author: 宋绍华
 * @Date: 2022-03-30 16:51:20
 * @LastEditTime: 2022-10-25 20:20:11
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\views\login\hooks\useLogin.ts
 */
import { getByUserId } from "@/api/request-modules/common";
import useCommon from "@/composables/useCommon";
import { getSession } from "@/utils";
import { getLocalhrefParams } from "@/utils/index";
const isDev = process.env.NODE_ENV === "development";
const CONFIG = {
  id: "canvasRef",
  appid: "ww06fa03084e27ff22",
  // agentid: isDev ? "1000131" : "1000131",
  // redirect_uri: isDev ? encodeURIComponent("https://test.tomato.rvet.cn") : encodeURIComponent("https://test.tomato.rvet.cn"),
  agentid: isDev ? "1000131" : "1000089",
  redirect_uri: isDev ? encodeURIComponent("https://test.tomato.rvet.cn") : encodeURIComponent("https://tomato.petrvet.com"),
  state: "",
  href: ""
};

export default function useLogin(drawEle: string): any {
  const { useLoginSuccess } = useCommon();

  const loginFn = () => {
    window.WwLogin(Object.assign(CONFIG, { id: drawEle }));
  };
  const getQRCode = async () => {
    const jumpUrl = getSession("jumpUrl") as string;
    if (typeof jumpUrl === "string" && jumpUrl.indexOf("code=") > -1) {
      // 已经扫码授权过
      const { code } = getLocalhrefParams(jumpUrl || "");
      if (code) {
        // 根据code获取成员信息
        try {
          // @ts-ignore
          const { data } = await getByUserId({ code });
          // 登录成功后业务回调
          data && useLoginSuccess(data);
          return;
        } catch (err) {
          // loginFn();
          // location.href = `/login`;
        }
      }
    }

    loginFn();
  };
  return { getQRCode };
}
