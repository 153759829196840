import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-306c5242"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-cascader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_cascader, {
      placeholder: "请选择",
      onChange: _ctx.handleChange,
      options: _ctx.peoples,
      "collapse-tags": "",
      filterable: "",
      ref: "cascaderValRef",
      clearable: "",
      "show-all-levels": true,
      "popper-append-to-body": false,
      teleported: false,
      props: _ctx.props
    }, null, 8, ["onChange", "options", "props"])
  ]))
}