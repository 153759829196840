
import { defineComponent, onMounted, ref } from "vue";
import useLogin from "./hooks/useLogin";
import { RouterLink } from "vue-router";
import Password from "./components/loginInput.vue";
import useCommon from "@/composables/useCommon";
import { ElMessage } from "element-plus";
import { USER } from "@/store/state";
import { Md5 } from "ts-md5";

export default defineComponent({
  components: {
    Password
  },
  props: {
    // @ts-ignore
    ...RouterLink.props,
    inactiveClass: String
  },
  setup() {
    const { getQRCode } = useLogin("canvasRef");
    const { usePasswordLogin, useLoginSuccess, useMessageLogin } = useCommon();
    const current = ref(0);
    const pwdRef = ref();
    const salt = "342ia194d103d4dsafj";
    onMounted(getQRCode);

    const checkLoginType = () => {
      current.value = current.value === 1 ? 2 : 1;
      // 切换时，需要手动调用更新二维码
      if (current.value === 1) {
        getQRCode();
      }
    };

    const onLogin = async (params: { mobile: string; passwd: string }) => {
      let data: USER;
      let msg = "";
      if (pwdRef.value.isInternal) {
        data = await pwdLogin(params);
      } else {
        const is_invite = sessionStorage.getItem("shortId") || undefined;
        const _d = await msgLogin({ mobile: params.mobile, code: params.passwd, is_invite });
        if (!_d) return;
        data = _d!.data;
        msg = _d!.message as string;
      }
      if (data) {
        ElMessage.success(msg || "登录成功");
        // 清除掉shortId
        setTimeout(() => {
          sessionStorage.removeItem("shortId");
          useLoginSuccess(data);
        }, 500);
      }
    };

    // 密码登录
    const pwdLogin = async (params: { mobile: string; passwd: string }) => {
      params.passwd = Md5.hashStr(`${params.passwd}${salt}`);
      const data = await usePasswordLogin(params);
      return data;
    };
    // 短信登录
    const msgLogin = async (params: { mobile: string; code: string; is_invite?: string }) => {
      const data = await useMessageLogin(params);
      return data;
    };

    return {
      pwdRef,
      onLogin,
      current,
      checkLoginType
    };
  }
});
