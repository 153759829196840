import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.valueId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueId) = $event)),
    "popper-class": "select-tree",
    clearable: _ctx.clearable,
    onClear: _ctx.clearHandle,
    ref: "rpSelect"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_option, {
        value: _ctx.valueId,
        label: _ctx.valueTitle
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tree, {
            id: "tree-option",
            ref: "selectTree",
            accordion: _ctx.accordion,
            data: _ctx.options,
            props: _ctx.props,
            filterable: "",
            "node-key": _ctx.props.value,
            "default-expanded-keys": _ctx.defaultExpandedKey,
            onNodeClick: _ctx.handleNodeClick
          }, null, 8, ["accordion", "data", "props", "node-key", "default-expanded-keys", "onNodeClick"])
        ]),
        _: 1
      }, 8, ["value", "label"])
    ]),
    _: 1
  }, 8, ["modelValue", "clearable", "onClear"]))
}